import ReactDOM from "react-dom/client";
import "./index.css";
import {
    Navigate,
    RouterProvider,
    createBrowserRouter,
} from "react-router-dom";
import { Play } from "./routes/play/Play";
import { Pick } from "./routes/pick/Pick";
import { Telegram } from "@twa-dev/types";

import "augmented-ui/augmented-ui.min.css";

declare global {
    interface Window {
        Telegram: Telegram;
    }
}

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

const router = createBrowserRouter([
    {
        path: "/",
        element: <Navigate to="/play" />,
    },
    {
        path: "/pick/:color?",
        element: <Pick />,
    },
    {
        path: "/play",
        element: <Play multiply={false} />,
    },
    {
        path: "/play/multiply",
        element: <Play multiply={true} />,
    },
]);

root.render(
    // <React.StrictMode>
    <RouterProvider router={router} />,
    // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
