type CardFrameProps = {
    color?: string;
};

export const CardFrame = (props: CardFrameProps) => {
    return (
        <svg
            className="relative w-full"
            viewBox="0 0 359 211"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.573303 8.86632C0.573303 3.97431 4.53906 0.00854492 9.43106 0.00854492H349.569C354.461 0.00854492 358.427 3.9743 358.427 8.8663V30.125L346.026 46.4078V166.534L358.427 182.478V201.965C358.427 206.857 354.461 210.823 349.569 210.823H9.43107C4.53907 210.823 0.573303 206.857 0.573303 201.965V182.478L12.9742 166.534V46.4078L0.573303 30.125V8.86632Z"
                fill={props.color ?? "#FF47D6"}
            />
        </svg>
    );
};
