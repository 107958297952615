type CardButtonProps = {
    color?: string;
};

export const CardButton = (props: CardButtonProps) => {
    return (
        <svg
            className="relative w-full"
            width="359"
            height="65"
            viewBox="0 0 359 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M358.427 0.487061H0.573303V64.2629H4.15184H347.884L358.427 45.8388V0.487061Z"
                fill={props.color ?? "#FF47D6"}
            />
        </svg>
    );
};
