import { Layout } from "../../components/Layout";
import { TypeAnimation } from "react-type-animation";
import clsx from "clsx";
import { agumentedContainerStyles } from "../../components/styles/augmentedContainerStyle";

type ErrorProps = {
    text: string;
};

export const Error = (props: ErrorProps) => {
    const textMapping = {
        "Not enough attempts available":
            "У вас недостаточно попыток для продолжения",
        "User didn't level up the last game":
            "В данный момент умножение недоступно",
        "User already reached max level":
            "Вы уже достигли максимального уровня",
    };

    const displayedError =
        Object.entries(textMapping).find(([needle]) =>
            props.text.includes(needle),
        )?.[1] ?? "Произошла непредвиденная ошибка";

    const closeWebApp = () => window.Telegram.WebApp.close();

    return (
        <Layout>
            <div
                data-augmented-ui="tl-2-clip-y br-2-clip-y both"
                className="flex flex-col justify-center items-stretch min-h-[150px] m-4"
                style={agumentedContainerStyles}
            >
                <div className="flex-grow flex flex-col justify-center p-1 pl-6">
                    <TypeAnimation
                        wrapper="div"
                        className="font-['Fira_Code'] text-lg font-bold text-red-500"
                        sequence={[displayedError]}
                        speed={75}
                        cursor={false}
                    />
                </div>
                <button
                    className={clsx(
                        "font-['Fira_Code'] block flex-grow-0 w-full p-2 text-center",
                        "bg-[rgba(9,126,126,0.5)] hover:bg-[rgba(9,126,126,0.85)]",
                        "transition ease-out duration-200 text-white",
                    )}
                    onClick={closeWebApp}
                >
                    Закрыть
                </button>
            </div>
        </Layout>
    );
};
