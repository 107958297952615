export const Spinner = () => {
    return (
        <svg
            className="w-40 h-40"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1000 1000"
            xmlSpace="preserve"
        >
            <circle
                className="st0"
                cx="500"
                cy="500"
                r="302.8"
                style={{
                    fill: "none",
                    stroke: "#00C196",
                    strokeWidth: 42,
                    strokeMiterlimit: 10,
                    strokeDasharray:
                        "12.1947,12.1947,12.1947,12.1947,12.1947,12.1947",
                }}
            >
                <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 500 500"
                    to="360 500 500"
                    dur="100s"
                    repeatCount="indefinite"
                />
            </circle>
            <circle
                className="st1"
                cx="500"
                cy="500"
                r="237.7"
                style={{
                    fill: "none",
                    stroke: "#00C196",
                    strokeWidth: 42,
                    strokeMiterlimit: 10,
                    strokeDasharray: "50,90,200,30,40,0",
                }}
            >
                <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 500 500"
                    to="360 500 500"
                    dur="40s"
                    repeatCount="indefinite"
                />
            </circle>
            <circle
                className="st2"
                cx="500"
                cy="500"
                r="366.8"
                transform="rotate(0 500 500)"
                style={{
                    fill: "none",
                    stroke: "#00C196",
                    strokeWidth: 42,
                    strokeMiterlimit: 10,
                    strokeLinecap: "square",
                    strokeDasharray: "120, 20, 110, 20, 140",
                }}
            >
                <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 500 500"
                    to="-360 500 500"
                    dur="50s"
                    repeatCount="indefinite"
                />
            </circle>
            <circle
                className="st3"
                cx="500"
                cy="500"
                r="385.1"
                style={{
                    fill: "none",
                    stroke: "#00C196",
                    strokeWidth: 16,
                    strokeMiterlimit: 10,
                    strokeLinecap: "square",
                }}
            />
        </svg>
    );
};
