import { useEffect, useRef, useState } from "react";
import { useGlitch } from "react-powerglitch";
import card from "../../assets/card.png";
import rocket from "../../assets/common/card/rocket.png";
import { Layout } from "../../components/Layout";
import { OpenedCard as OpenedCardComponent } from "../../components/OpenedCard";
import { OpenedCard } from "./api";
import { agumentedContainerStyles } from "../../components/styles/augmentedContainerStyle";

type SelectCardProps = {
    openedCards: OpenedCard[];
    onSelectCard: (position: number) => void;
    shouldOpenCards: number;
};

export const SelectCard = (props: SelectCardProps) => {
    const prevOpenedCards = useRef<number[]>(
        props.openedCards.map((card) => card.position),
    );
    const [newlyOpenedCards, setNewlyOpenedCards] = useState<number[]>([]);
    const [kekpek, setKekpek] = useState({});

    const glitch = useGlitch({
        playMode: "manual",
        createContainers: false,
        hideOverflow: false,
        timing: {
            duration: 250,
            iterations: 1,
        },
        glitchTimeSpan: {
            start: 0,
            end: 1,
        },
        shake: {
            velocity: 15,
            amplitudeX: 0.2,
            amplitudeY: 0.2,
        },
        slice: {
            count: 15,
            velocity: 20,
            minHeight: 0.02,
            maxHeight: 0.15,
            hueRotate: true,
        },
        pulse: false,
    });

    useEffect(() => {
        if (
            props.openedCards.some(
                (openedCard) =>
                    prevOpenedCards.current.indexOf(openedCard.position) === -1,
            )
        ) {
            setNewlyOpenedCards(
                props.openedCards
                    .map((openedCard) => openedCard.position)
                    .filter(
                        (position) =>
                            prevOpenedCards.current.indexOf(position) === -1,
                    ),
            );

            prevOpenedCards.current = props.openedCards.map(
                (openedCard) => openedCard.position,
            );
        }
    }, [props.openedCards, prevOpenedCards, setNewlyOpenedCards]);

    useEffect(() => {
        if (newlyOpenedCards.length > 0) {
            setKekpek({});
            setNewlyOpenedCards([]);
        }
    }, [newlyOpenedCards, setNewlyOpenedCards, setKekpek]);

    useEffect(() => {
        if (kekpek) {
            glitch.startGlitch();
        }

        // Shouldn't rerender when glitch ref changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kekpek]);

    return (
        <Layout>
            <div className="h-screen flex flex-col">
                <div className="flex-grow flex-col justify-center items-center overflow-auto p-[20px] pb-[10px] xl:pl-[200px] xl:pr-[200px]">
                    <div className="grid grid-cols-3 lg:grid-cols-6 md:grid-cols-4 gap-x-[15px] gap-y-[10px] md:gap-[26px]">
                        {Array(12)
                            .fill(null)
                            .map((_, i) =>
                                props.openedCards.find(
                                    (card) => card.position === i,
                                ),
                            )
                            .map((openedCard, i) =>
                                openedCard === undefined ? (
                                    <div
                                        className="relative cursor-pointer"
                                        key={i}
                                        onClick={() => props.onSelectCard(i)}
                                    >
                                        <img
                                            className="relative w-full"
                                            src={card}
                                            alt="Card"
                                            draggable="false"
                                        />
                                        <div className="absolute w-full h-full top-0 left-0 flex justify-center items-center">
                                            <img
                                                className="relative w-[52.5%]"
                                                src={rocket}
                                                alt="Rocket"
                                                draggable="false"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div key={i}>
                                        <div
                                            ref={
                                                newlyOpenedCards.indexOf(
                                                    openedCard.position,
                                                ) !== -1
                                                    ? glitch.ref
                                                    : undefined
                                            }
                                        >
                                            <OpenedCardComponent
                                                onClick={() =>
                                                    props.onSelectCard(i)
                                                }
                                                color={`#${openedCard.card.color}`}
                                                text={openedCard.card.name}
                                            />
                                        </div>
                                    </div>
                                ),
                            )}
                    </div>
                </div>
                <div
                    data-augmented-ui="tl-clip br-clip both"
                    style={agumentedContainerStyles}
                    className="flex-grow-0 m-3 p-1 pl-2 pr-2 font-fira text-sm text-center text-white"
                >
                    {props.shouldOpenCards === props.openedCards.length ? (
                        <>Нажмите на любую карту, чтобы продолжить</>
                    ) : (
                        <>
                            Откройте еще{" "}
                            {props.shouldOpenCards - props.openedCards.length}{" "}
                            {props.shouldOpenCards -
                                props.openedCards.length ===
                            1
                                ? "карту"
                                : "карты"}
                        </>
                    )}
                </div>
            </div>
        </Layout>
    );
};
