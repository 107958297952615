import card from "../assets/card.png";
import clsx from "clsx";
import { CardButton } from "./CardButton";
import { CardFrame } from "./CardFrame";

type OpenedCardProps = {
    text: string;
    color?: string;
    large?: boolean;
    onClick?: () => void;
    onButtonClick?: () => void;
};

export const OpenedCard = (props: OpenedCardProps) => {
    return (
        <div onClick={props.onClick} className="relative flex-grow max-w-xs">
            <img
                className="relative w-full"
                src={card}
                alt="Card"
                draggable="false"
            />
            <div className="absolute font-['Fira_Code'] text-white w-full h-full top-0 left-0 pl-[4.5%] pr-[4.5%] flex flex-col justify-evenly items-center">
                <div className="relative">
                    <CardFrame color={props.color} />
                    <div className="absolute w-full h-full top-0 left-0 p-2 flex flex-col justify-center items-center overflow-hidden">
                        <div className="relative">
                            <p
                                className={clsx(
                                    "text-center uppercase",
                                    props.large
                                        ? "md:text-3xl text-3xl"
                                        : "md:text-lg sm:text-sm text-xs",
                                )}
                            >
                                Ваш приз
                            </p>
                            <p
                                className={clsx(
                                    "font-bold whitespace-nowrap",
                                    props.large
                                        ? "md:text-4xl text-4xl"
                                        : "md:text-lg sm:text-sm text-xs",
                                )}
                            >
                                {props.text}
                            </p>
                        </div>
                    </div>
                </div>
                <button
                    className={clsx(
                        "relative",
                        props.onButtonClick
                            ? "cursor-pointer"
                            : "cursor-default",
                    )}
                    onClick={props.onButtonClick}
                >
                    <CardButton color={props.color} />
                    <div className="absolute w-full h-full top-0 left-0 flex justify-center items-center">
                        <p
                            className={clsx(
                                props.large
                                    ? "md:text-xl text-xl"
                                    : "md:text-base sm:text-sm text-xs",
                            )}
                        >
                            Получить
                        </p>
                    </div>
                </button>
            </div>
        </div>
    );
};
