import { useCallback, useEffect, useRef, useState } from "react";
import { Spinner } from "../../components/Spinner";
import { GameState, fetchAPI } from "./api";
import clsx from "clsx";
import { AcceptRules } from "./AcceptRules";
import { SelectCard } from "./SelectCard";
import { Win } from "./Win";
import { Error as ErrorComponent } from "./Error";
import { Lose } from "./Lose";

const SHOULD_OPEN_CARDS = 2;

type PlayProps = {
    multiply: boolean;
};

export const Play = (props: PlayProps) => {
    const [state, setState] = useState<GameState | null>(null);
    const [displaySpinner, setDisplaySpinner] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const isDoingPost = useRef(false);

    const fetchAPISafe = useCallback(
        (...args: Parameters<typeof fetchAPI>): Promise<GameState> => {
            if (isDoingPost.current) {
                return new Promise(() => {});
            }

            isDoingPost.current = true;
            return fetchAPI(...args)
                .then((data) => {
                    isDoingPost.current = false;
                    return data;
                })
                .then((maybeData) =>
                    "state" in maybeData
                        ? Promise.resolve(maybeData)
                        : Promise.reject(new Error(maybeData.error)),
                )
                .catch((error) => {
                    setError(error.message);

                    return new Promise(() => {});
                });
        },
        [setError],
    );

    const initState = useCallback((newState: GameState) => {
        setState((state) => {
            if (state !== null) {
                return state;
            }

            setTimeout(() => setDisplaySpinner(false), 2000);
            return newState;
        });
    }, []);

    useEffect(() => {
        fetchAPISafe("/api/game", "GET").then((data) => {
            if (props.multiply && !data?.state.startsWith("MULTIPLY")) {
                fetchAPISafe("/api/game/multiply", "POST").then((data) => {
                    initState(data);
                });
            } else {
                initState(data);
            }
        });
        // Ignore props change after initial render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const acceptRules = useCallback(() => {
        if (state?.state !== "ACCEPT_RULES") {
            return;
        }

        fetchAPISafe("/api/game/rules", "POST").then((data) => setState(data));
    }, [state?.state, fetchAPISafe]);

    const selectCard = useCallback(
        (position: number) => {
            if (state?.state !== "SELECT_CARD") {
                return;
            }

            if (state.opened_cards.length < SHOULD_OPEN_CARDS) {
                if (
                    !state.opened_cards.some(
                        (card) => card.position === position,
                    )
                ) {
                    fetchAPISafe("/api/game/card", "POST", { position }).then(
                        (data) => setState(data),
                    );
                }
            } else {
                fetchAPISafe("/api/game/show", "POST").then((data) =>
                    setState(data),
                );
            }
        },
        [state?.state, state?.opened_cards, fetchAPISafe],
    );

    const selectMultiplyCard = useCallback(
        (position: number) => {
            if (state?.state !== "MULTIPLY" || state.opened_cards.length >= 1) {
                return;
            }

            fetchAPISafe("/api/game/multiply/card", "POST", { position }).then(
                (data) => setState(data),
            );
        },
        [state?.state, state?.opened_cards, fetchAPISafe],
    );

    const resetMultiply = useCallback(() => {
        if (state?.state !== "MULTIPLY") {
            return;
        }

        fetchAPISafe("/api/game/multiply/reset", "POST").then((data) =>
            setState(data),
        );
    }, [state?.state, fetchAPISafe]);

    const reset = useCallback(() => {
        if (state?.state !== "WIN" && state?.state !== "LOSE") {
            return;
        }

        fetchAPISafe("/api/game/reset", "POST").then((data) => {
            setState(data);

            window.Telegram.WebApp.close();
        });
    }, [fetchAPISafe, state?.state]);

    return (
        <div className="bg-[url(../public/images/background.png)] bg-cover min-h-screen flex flex-col justify-center">
            {error ? (
                <ErrorComponent text={error} />
            ) : (
                <>
                    {displaySpinner && (
                        <div className="pointer-events-none fixed inset-0 flex items-center justify-center">
                            <div
                                className={clsx(
                                    "transition-all ease-out",
                                    state ? "opacity-0" : "opacity-100",
                                )}
                            >
                                <div className="animate-pulse flex items-center justify-center">
                                    <Spinner />
                                </div>
                            </div>
                        </div>
                    )}

                    {state?.state === "ACCEPT_RULES" && (
                        <AcceptRules onAcceptRules={acceptRules} />
                    )}

                    {state?.state === "SELECT_CARD" && (
                        <SelectCard
                            openedCards={state.opened_cards}
                            shouldOpenCards={SHOULD_OPEN_CARDS}
                            onSelectCard={selectCard}
                        />
                    )}

                    {state?.state === "WIN" && (
                        <Win
                            card={state.opened_cards[0].card}
                            onClaim={reset}
                        />
                    )}

                    {state?.state === "LOSE" && (
                        // <Win
                        //     card={{
                        //         name: "Вы проиграли",
                        //         color: "999999",
                        //         type: "REGULAR",
                        //         reward: null,
                        //         id: 0,
                        //     }}
                        //     onClaim={reset}
                        // />
                        <Lose onClose={reset} />
                    )}

                    {state?.state === "MULTIPLY" && (
                        <SelectCard
                            openedCards={state.opened_cards}
                            shouldOpenCards={1}
                            onSelectCard={selectMultiplyCard}
                        />
                    )}

                    {state?.state === "MULTIPLY_WIN" && (
                        <Win
                            card={state.opened_cards[0].card}
                            onClaim={resetMultiply}
                        />
                    )}

                    {state?.state === "MULTIPLY_LOSE" && (
                        <Lose onClose={resetMultiply} />
                    )}
                </>
            )}
        </div>
    );
};
