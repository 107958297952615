export type GameState = {
    state:
        | "ACCEPT_RULES"
        | "SELECT_CARD"
        | "WIN"
        | "LOSE"
        | "MULTIPLY"
        | "MULTIPLY_WIN"
        | "MULTIPLY_LOSE";
    level: number;
    opened_cards: OpenedCard[];
};

export type Card = {
    id: number;
    name: string;
    type: "REGULAR" | "LEVEL_UP" | "LEVEL_DOWN" | "BLACK_HOLE";
    reward: string | null;
    color: string;
};

export type OpenedCard = {
    card: Card;
    position: number;
};

export type APIError = {
    error: "invalid data" | "invalid game state";
};

export const fetchAPI = (
    path: string,
    method: "GET" | "POST",
    body?: Record<string, any>,
): Promise<GameState | Partial<APIError>> => {
    return fetch(path, {
        method,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Telegram ${window.Telegram.WebApp.initData}`,
        },
        body: body && JSON.stringify(body),
    }).then((data) => data.json());
};
