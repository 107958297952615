import { CSSProperties } from "react";

export const agumentedContainerStyles = {
    "--aug-inlay-all": 0,
    // "--aug-inlay-bg": "hsla(180 75% 10% / 0.5)",
    "--aug-inlay-bg": "rgba(0,51,51,.5)",
    "--aug-border-all": "1px",
    // "--aug-border-bg": "hsl(180, 75%, 50%)",
    "--aug-border-bg": "#097e7e",
} as CSSProperties;
