import pokerCards from "../../assets/play/welcome/poker-cards-1.png";
import volume from "../../assets/play/welcome/volume-1.png";
import { ReactComponent as GgShareComponent } from "../../assets/play/welcome/gg-share.svg";
import ReactDOMServer from "react-dom/server";
import { ReactElement } from "react";

export type AcceptRulesProps = {
    onAcceptRules?: () => void;
};

export function encodeSvg(reactElement: ReactElement) {
    return (
        "data:image/svg+xml," +
        escape(ReactDOMServer.renderToStaticMarkup(reactElement))
    );
}

export const AcceptRules = (props: AcceptRulesProps): JSX.Element => {
    const ggShare = encodeSvg(<GgShareComponent />);

    return (
        <>
            <div className="flex flex-col justify-start items-center pl-[30px] pr-[30px]">
                <p className="font-['Fira_Code'] mb-[45px] 2xl:mb-[75px] font-bold text-center text-white text-3xl 2xl:text-[4rem] leading-[normal]">
                    Приветствуем в Cosmo Cash Game!
                </p>

                <div className="max-w-[800px] flex flex-col items-start justify-start">
                    <div className="max-w-[800px] mb-[25px] 2xl:mb-[75px] flex flex-row items-start 2xl:items-center justify-start">
                        <img
                            className="w-[34px] h-[34px] 2xl:w-[72px] 2xl:h-[72px] mr-[10px] 2xl:mr-[33px] object-cover"
                            alt="Poker cards"
                            src={pokerCards}
                        />
                        <p className="font-['Helvetica'] font-normal text-white text-lg 2xl:text-[2rem] leading-[normal]">
                            Перед вами 12 карт для выбора приза
                        </p>
                    </div>

                    <div className="max-w-[800px] mb-[25px] 2xl:mb-[75px] flex flex-row items-start 2xl:items-center">
                        <img
                            className="w-[34px] h-[34px] 2xl:w-[72px] 2xl:h-[72px] mr-[10px] 2xl:mr-[33px] object-cover"
                            alt="Volume"
                            src={volume}
                        />
                        <p className="font-['Helvetica'] font-normal text-white text-lg 2xl:text-[2rem] leading-[normal]">
                            Level up означает подъем на новый уровень с
                            увеличением стоимости призов
                        </p>
                    </div>

                    <div className="max-w-[800px] mb-[25px] 2xl:mb-[75px] flex flex-row items-start 2xl:items-center">
                        <img
                            className="w-[34px] h-[34px] 2xl:w-[72px] 2xl:h-[72px] mr-[10px] 2xl:mr-[33px] object-cover"
                            alt="Gg share"
                            src={ggShare}
                        />
                        <p className="font-['Helvetica'] font-normal text-white text-lg 2xl:text-[2rem] leading-[normal]">
                            Получить дополнительные попытки можно привлекая
                            друзей, а так же выполняя задания
                        </p>
                    </div>

                    <button
                        onClick={props.onAcceptRules}
                        className="w-full h-[49px] 2xl:h-[87px] bg-[url(../public/images/button.svg)] [background-size:100%_100%] flex justify-center items-center"
                    >
                        <div className="font-['Helvetica'] font-semibold text-white text-lg 2xl:text-[2rem] text-center">
                            Начать игру
                        </div>
                    </button>
                </div>
            </div>
        </>
    );
};
