import clsx from "clsx";
import card from "../../assets/card.png";
import { CardFrame } from "../../components/CardFrame";
import { Layout } from "../../components/Layout";
import { CardButton } from "../../components/CardButton";

type LoseProps = {
    onClose?: () => void;
};

export const Lose = (props: LoseProps) => {
    return (
        <Layout>
            <div className="flex justify-center items-center">
                <div
                    onClick={props.onClose}
                    className="relative flex-grow max-w-xs"
                >
                    <img
                        className="relative w-full"
                        src={card}
                        alt="Card"
                        draggable="false"
                    />
                    <div className="absolute font-['Fira_Code'] text-white w-full h-full top-0 left-0 pl-[4.5%] pr-[4.5%] flex flex-col justify-evenly items-center">
                        <div className="relative">
                            <CardFrame color="#999999" />
                            <div className="absolute w-full h-full top-0 left-0 p-2 flex flex-col justify-center items-center overflow-hidden">
                                <div className="relative">
                                    {/* <p
                                className="text-center uppercase md:text-3xl text-3xl"
                            >
                                Ваш приз
                            </p> */}
                                    <p className="font-bold whitespace-nowrap md:text-4xl text-4xl">
                                        Вы проиграли
                                    </p>
                                </div>
                            </div>
                        </div>
                        <button
                            className={clsx(
                                "relative",
                                props.onClose
                                    ? "cursor-pointer"
                                    : "cursor-default",
                            )}
                            onClick={props.onClose}
                        >
                            <CardButton color="#999999" />
                            <div className="absolute w-full h-full top-0 left-0 flex justify-center items-center">
                                <p className="md:text-xl text-xl">Принять</p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
