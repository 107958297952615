import { Layout } from "../../components/Layout";
import { OpenedCard } from "../../components/OpenedCard";
import { Card } from "./api";

type WinProps = {
    card: Card;
    onClaim?: () => void;
};

export const Win = (props: WinProps) => {
    return (
        <Layout>
            <div className="flex justify-center items-center">
                <OpenedCard
                    text={props.card.name}
                    color={`#${props.card.color}`}
                    large={true}
                    onButtonClick={props.onClaim}
                />
            </div>
        </Layout>
    );
};
