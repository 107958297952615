import { useState } from "react";
import { SketchPicker } from "react-color";
import { useParams } from "react-router-dom";

export const Pick = () => {
    const { color: defaultColor } = useParams();
    const [color, setColor] = useState(
        defaultColor ? `#${defaultColor}` : "#ff47d6",
    );

    const submit = () => {
        fetch("/api/query", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                query_id: window.Telegram.WebApp.initDataUnsafe.query_id,
                text: `/color ${color.replace("#", "")}`,
            }),
        }).then(() => window.Telegram.WebApp.close());
    };

    console.log(window.Telegram.WebApp.initData);

    return (
        <div className="flex flex-row justify-center align-middle h-screen">
            <div className="flex flex-col justify-center">
                <SketchPicker
                    color={color}
                    onChange={(color) => setColor(color.hex)}
                />
                <button
                    className="font-['Fira_Code'] p-3 bg-slate-700 text-white"
                    type="button"
                    onClick={submit}
                >
                    Сохранить
                </button>
            </div>
        </div>
    );
};
